export const IS_LOADING = 'IS_LOADING'
export const IS_LOADED = 'IS_LOADED'
export const SET_PRODUCT_DATA = 'SET_PRODUCT_DATA'
export const SET_PRODUCT_DESC = 'SET_PRODUCT_DESC'


export function isLoading() {
    return { type: IS_LOADING };
}
export function isLoaded() {
    return { type: IS_LOADED }
}
export function setProduct(data) {
    return {
        type: SET_PRODUCT_DATA,
        payLoad: data
    }
}
export function updateDesc(data) {
    return {
        type: SET_PRODUCT_DESC,
        payLoad: data

    }
}