import React, { useReducer } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    reducer, intialState
} from './reducer';
import { updateEmail, updatePassword } from './actions';
import { login } from './network';

function Login() {

    const [state, dispatch] = useReducer(reducer, intialState)

    if (state.redirect) {
        return <Redirect to={state.path} />
    }

    return (
        <div>
            <div className="wrapper fadeInDown" style={{ paddingTop: '10%' }}>
                <div id="formContent">
                    <div className="fadeIn first">
                        <img src="/assets/img/logo.png" id="icon" alt="User Icon" />
                    </div>
                    <form>
                        <input type="email" id="login" className="fadeIn second" name="login" placeholder="Email" onChange={(e) => dispatch(updateEmail(e.target.value))} />
                        <input type="password" id="password" className="fadeIn third" name="login" placeholder="Password" onChange={(e) => dispatch(updatePassword(e.target.value))} />
                        <input type="button" className="fadeIn fourth" value="Sign In" onClick={() => login(dispatch, state)} />
                    </form>
                    <div id="formFooter">
                        <Link className="underlineHover" style={{ paddingRight: '10px' }} to="/forget">Forgot Password?</Link>
                        <Link className="underlineHover" to="/register">New User?</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login