import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { removeToken } from '../../../Common/Network/storage'

export default function Nav() {

    const [redirect, setRedirect] = useState(false)

    const handleLogout = () => {
        removeToken()
        setRedirect(true)
    }

    if (redirect) {
        return <Redirect to="/" />
    }

    return (
        <div>
            <nav className="navbar fixed-top navbar-expand-md navbar-dark bg-primary mb-3">
                <div className="flex-row d-flex">
                    <button type="button" className="navbar-toggler mr-2 " data-toggle="offcanvas" title="Toggle responsive left sidebar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <Link className="navbar-brand" to="/home"><img alt="logo" src="assets/img/home_logo.png" width={'200px'} /></Link>
                </div>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="navbar-collapse collapse" id="collapsingNavbar">
                    {/* <ul className="navbar-nav">
                        <li className="nav-item active">
                            <a className="nav-link" href="#">Home <span className="sr-only">Home</span></a>
                        </li>
                    </ul> */}
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/home">Home</Link>
                        </li>
                        <li className="nav-item">
                            <li className="nav-link" onClick={() => handleLogout()} >Logout</li>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}