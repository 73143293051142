import network from '../../../../Common/Network'
import * as action from './actions';
import { Alert } from '../../../../Common/Alerts'



export async function getKey(dispatch) {
    dispatch(action.isLoading())
    try {
        var response = await network.get('productApi/getKey', true)
        dispatch(action.getProductKey(response))
    } catch (error) {
        Alert('warning', error.message)
    }
    dispatch(action.isLoaded())
}

export async function getProductTypes(dispatch) {
    dispatch(action.isLoading())
    try {
        var response = await network.get('productApi/getProductType', true)
        dispatch(action.getTypeList(response))
    } catch (error) {
        Alert('warning', error.message)
    }
    dispatch(action.isLoaded())
}

export async function handleSubmit(dispatch, state, data, file) {
    if (state.productType && state.productName && data && file) {


        dispatch(action.isLoading())
        try {
            var response = await network.post('productApi/addSubProduct', {
                'productType': state.productType.value,
                'productName': state.productName,
                'data': data,
                'file': file.result
            }, {}, true)
            Alert('success', response.message)
            dispatch(action.setRedirect('/adminSubProducts'))
        } catch (error) {
            Alert('warning', error.message)
        }
        dispatch(action.isLoaded())
    } else {
        Alert('warning', "Please Fill All Fields")
    }
}

export async function getSubProduct(dispatch) {
    dispatch(action.isLoading())
    try {
        var response = await network.get('productApi/getSubProductType', false)
        console.log(response)
        dispatch(action.getSubProductType(response))
    } catch (error) {
        Alert('warning', error.message)
    }
    dispatch(action.isLoaded())
}