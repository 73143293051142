import React, { useEffect, useReducer, useState } from 'react';
import Navbar from '../Common/Navbar';
import Footer from '../Common/Footer';
import { reducer, initialState } from './reducer';
import { getProductType, getSubProduct } from './network';
import { Link } from 'react-router-dom'
import { sendEmail } from './network'
import CircularProgress from '@material-ui/core/CircularProgress';


function LandingPage() {

    const [state, dispatch] = useReducer(reducer, initialState)
    const [email, setEmail] = useState({})
    const handleChange = (e) => {
        setEmail({
            ...email,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        getProductType(dispatch)
        getSubProduct(dispatch)
    }, [])

    return (
        <div>
            <Navbar />
            <section id="hero">
                <div className="hero-container" data-aos="fade-up">
                    <h1>Welcome to Inoveda</h1>
                    <h2>International General Trading LLC</h2>
                    <a href="#about" className="btn-get-started scrollto"><i className="bx bx-chevrons-down"></i></a>
                </div>
            </section>
            <main id="main">
                <section id="about" className="about">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="content col-xl-12 d-flex align-items-stretch" data-aos="fade-up">
                                <div className="content">
                                    <h3>About us</h3>
                                    <p className="description" style={{ fontStyle: "bold", fontSize: '20px' }}>
                                        Inoveda International General Trading LLC is associated with some of the biggest brands in the region,a trusted link connecting brands to wholesale and retail market in UAE. We are the trade strategy experts of the wholesale market in the region with a diverse portfolio of brands for a variety of consumer needs
              </p>
                                    {/* <a href="#" className="about-btn">About us <i className="bx bx-chevron-right"></i></a> */}
                                </div>
                            </div>
                            {/* <div className="col-xl-7 d-flex align-items-stretch">
                                <div className="icon-boxes d-flex flex-column justify-content-center">
                                    <div className="row">
                                        <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                                            <h4>Food Stuff Products</h4>
                                        </div>
                                        <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                                            <h4>Neutaceuticals Products</h4>
                                        </div>
                                        <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                                            <h4>Petrochemical Products</h4>
                                        </div>
                                        <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                                            <h4>Office Products</h4>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                    </div>
                </section>
                {
                    state.isLoading ? <CircularProgress style={{ marginTop: '18%', marginLeft: '50%' }} disableShrink /> :
                        <div>
                            <section id="products" className="products">
                                <div className="container">

                                    <div className="section-title" data-aos="fade-in" data-aos-delay="100">
                                        <h2>Products</h2>
                                        {/* <p></p> */}
                                    </div>

                                    <div className="row" data-aos="fade-in">
                                        <div className="col-lg-12 d-flex justify-content-center">
                                            <ul id="products-flters">
                                                <li data-filter="*" className="filter-active">All</li>
                                                {
                                                    state.productType.map((x, key) => {
                                                        return (
                                                            <Link
                                                                to={{
                                                                    pathname: '/productList',
                                                                    state: { pType: x.id }
                                                                }}
                                                            ><li key={key} data-filter={`.filter-${x.name}`}>{x.name}</li></Link>
                                                        )

                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="row products-container" data-aos="fade-up">
                                        {
                                            state.subProductType.map((x, key) => {
                                                return (
                                                    <div key={key} className={`col-lg-4 col-md-6 products-item filter-${x.typeName}`}>
                                                        <div className="products-wrap">
                                                            <img src={x.image_path} className="img-fluid" style={{
                                                                width: '400px',
                                                                height: '200px',
                                                                objectFit: 'cover'
                                                            }} alt="" />
                                                            <div className="products-links">
                                                                <Link to={{
                                                                    pathname: '/productList',
                                                                    state: { pType: x.product_type }
                                                                }} title="More Details for Rice"><i className="bx bx-link"></i></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )

                                            })
                                        }
                                    </div>

                                </div>
                            </section>
                        </div>
                }
                <section id="contact" className="contact section-bg">
                    <div className="container">
                        <div className="section-title" data-aos="fade-in" data-aos-delay="100">
                            <h2>Contact</h2>
                        </div>

                        <div className="row" data-aos="fade-up" data-aos-delay="100">
                            <div className="col-lg-6">
                                <div className="info-box mb-4">
                                    <i className="bx bx-map"></i>
                                    <h3>Our Address</h3>
                                    <p>Al Qusais Industrial AreaAl Qusais Industrial Area 3</p><p> Dubai - United Arab Emirates</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="info-box  mb-4">
                                    <i className="bx bx-envelope"></i>
                                    <h3>Email Us</h3>
                                    <br />
                                    <p>contact@innovedaintl.com</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="info-box  mb-4">
                                    <i className="bx bx-phone-call"></i>
                                    <h3>Call Us</h3>
                                    <br />
                                    <p>+971 52 436 4847</p>
                                </div>
                            </div>

                        </div>

                        <div className="row" data-aos="fade-up" data-aos-delay="200">

                            <div className="col-lg-6 ">
                                <iframe className="mb-4 mb-lg-0" title="maps" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14428.796046320993!2d55.391938!3d25.2975169!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2341fb3229c23416!2sInnoveda%20International%20General%20Trading%20LLC!5e0!3m2!1sen!2sin!4v1591518992386!5m2!1sen!2sin" frameBorder="0" style={{ 'border': '0', 'width': '100%', 'height': '384px' }} allowFullScreen></iframe>
                            </div>

                            <div className="col-lg-6">
                                <form onSubmit={(e) => sendEmail(e, dispatch, email)} className="php-email-form">
                                    <div className="form-row">
                                        <div className="col-md-6 form-group">
                                            <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" onChange={(e) => handleChange(e)} required />
                                            <div className="validate"></div>
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" onChange={(e) => handleChange(e)} required />
                                            <div className="validate"></div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" onChange={(e) => handleChange(e)} required />
                                        <div className="validate"></div>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message" onChange={(e) => handleChange(e)} required></textarea>
                                        <div className="validate"></div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Your message has been sent. Thank you!</div>
                                    </div>
                                    <div className="text-center"><button className="btn" type="submit" >Send Message</button></div>
                                </form>
                            </div>

                        </div>

                    </div>
                </section>
            </main>
            <Footer />
        </div >
    )
}
export default LandingPage;