import network from '../../Common/Network'
import * as action from './actions';
import { Alert } from '../../Common/Alerts'
import { setToken } from '../../Common/Network/storage'
import jwt_decode from "jwt-decode";


export async function login(dispatch, state) {
    if (state.email.value && state.password.value) {
        dispatch(action.isLoading())
        try {
            var response = await network.post('authApi/userLogin', {
                'email': state.email.value,
                'password': state.password.value
            }, {}, false
            )
            setToken(response.accessToken)
            Alert('success', response.message)
            const tokenDetails = jwt_decode(response.accessToken);
            dispatch(action.setCurrentUser(tokenDetails))
            dispatch(action.setRedirect('/home'))
        } catch (error) {
            Alert('warning', error.message)
        }
        dispatch(action.isLoaded())
    } else {
        Alert('warning', "Please Fill All Fields")
    }
}