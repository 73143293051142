export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const IS_LOADING = 'IS_LOADING'
export const IS_LOADED = 'IS_LOADED'
export const IS_REDIRECT = 'IS_REDIRECT'

export function isLoading() {
    return { type: IS_LOADING };
}
export function isLoaded() {
    return { type: IS_LOADED }
}
export function updateEmail(email) {
    return {
        type: UPDATE_EMAIL,
        payLoad: email
    }
}
export function updatePassword(password) {
    return {
        type: UPDATE_PASSWORD,
        payLoad: password
    }
}
export const setRedirect = (path) => {
    return {
        type: IS_REDIRECT,
        payload: path
    }
}

export const setCurrentUser = (tokenDetails) => {
    console.log(tokenDetails)
    return {
        type: SET_CURRENT_USER,
        payload: tokenDetails
    };
};