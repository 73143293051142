import network from '../../Common/Network'
import * as action from './action';
import { Alert } from '../../Common/Alerts'

export async function getProductDetails(dispatch, pType) {
    dispatch(action.isLoading())
    try {
        var response = await network.post('productApi/getProductDetails', {
            'productType': pType
        }, {}, false)
        dispatch(action.getProductData(response))
    } catch (error) {
        Alert('warning', error.message)
    }
    dispatch(action.isLoaded())
}

export async function getProductDesc(dispatch, id) {
    dispatch(action.isLoading())
    try {
        var response = await network.post('productApi/getProductDesc', {
            'id': id
        }, {}, false)
        dispatch(action.getProductDesc(response))
    } catch (error) {
        Alert('warning', error.message)
    }
    dispatch(action.isLoaded())
}
