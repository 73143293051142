export const IS_LOADING = 'IS_LOADING'
export const IS_LOADED = 'IS_LOADED'
export const GET_PRODUCT_DATA = 'GET_PRODUCT_DATA'
export const GET_PRODUCT_DESC = 'GET_PRODUCT_DESC'

export function isLoading() {
    return { type: IS_LOADING };
}
export function isLoaded() {
    return { type: IS_LOADED }
}
export function getProductData(data) {
    return {
        type: GET_PRODUCT_DATA,
        payLoad: data
    }
}
export function getProductDesc(data) {
    return {
        type: GET_PRODUCT_DESC,
        payLoad: data
    }
}

