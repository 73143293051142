import * as actions from './actions'
import * as validate from '../../Common/Validator';
const isEmpty = require("is-empty");

export const intialState = {
    isloading: false,
    email: {
        value: "",
        errorStatus: false,
        errorMessage: "Enter Valid Email"
    },
    password: {
        value: "",
        errorStatus: false,
        errorMessage: "Enter Valid Password"
    },
    redirect: false,
    path: "",
    user: {},
    isAuthenticated: false
}



export function reducer(state = intialState, action) {
    switch (action.type) {
        case actions.UPDATE_EMAIL:
            return {
                ...state,
                email: {
                    ...state.email,
                    value: action.payLoad,
                    errorStatus: validate.validateEmail(action.payLoad) ? false : true
                },
            };
        case actions.UPDATE_PASSWORD:
            return {
                ...state,
                password: {
                    ...state.password,
                    value: action.payLoad,
                    errorStatus: validate.validatePassword(action.payLoad) ? false : true
                },
            };
        case actions.IS_LOADING:
            return { ...state, isLoading: true }
        case actions.IS_LOADED:
            return { ...state, isLoading: false }
        case actions.SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case actions.IS_REDIRECT:
            return {
                ...state,
                redirect: true,
                path: action.payload
            };
        default: return state
    }
}

