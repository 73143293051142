import network from '../Common/Network'
import * as action from './action';
import { Alert } from '../Common/Alerts'



export async function getProductType(dispatch) {
    dispatch(action.isLoading())
    try {
        var response = await network.get('productApi/getProductType', false)
        dispatch(action.getProductType(response))
    } catch (error) {
        Alert('warning', error.message)
    }
    dispatch(action.isLoaded())
}

export async function getSubProduct(dispatch) {
    dispatch(action.isLoading())
    try {
        var response = await network.get('productApi/getSubProductType', false)
        dispatch(action.getSubProductType(response))
    } catch (error) {
        Alert('warning', error.message)
    }
    dispatch(action.isLoaded())
}

export async function sendEmail(e,dispatch, email) {
    e.preventDefault();
    dispatch(action.isLoading())
    try {
        var response = await network.post('customerApi/sendMessage', {
            data: email
        }, {}, false)
        dispatch(action.redirect())
        Alert('success', response.message)
    } catch (error) {
        Alert('warning', error.message)
    }
    dispatch(action.isLoaded())
}