import React from 'react';
import { Link } from 'react-router-dom';

function ForgetPassword() {
    return (
        <div>
            <div className="wrapper fadeInDown" style={{ paddingTop: '10%' }}>
                <div id="formContent">
                    <div className="fadeIn first">
                        <img src="/assets/img/logo.png" id="icon" alt="User Icon" />
                    </div>

                    <form>
                        <input type="email" id="login" className="fadeIn second" name="login" placeholder="Email" />
                        <input type="button" className="fadeIn fourth" value="Reset" />
                    </form>
                    <div id="formFooter">
                        <Link className="underlineHover" to="/admin">you know password ?</Link>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ForgetPassword