import React, { useReducer, useEffect, useState } from 'react';
import Nav from '../../Navbar'
import SideNav from '../../SideNav';
import Footer from '../../Footer';
import { updateDes } from './action'
import { addDescription, getKey } from './network'
import { reducer, initialState } from './reducer'
import MaterialTable from 'material-table'
import UpdateModal from '../../../../Common/Modal'
import { Redirect } from 'react-router-dom'

export default function AdminDescription() {

    const [state, dispatch] = useReducer(reducer, initialState)
    const [open, setOpen] = useState(false)
    const [id, setId] = useState("")

    useEffect(() => {
        getKey(dispatch)
    }, [])

    const handleOpen = (id) => {
        setOpen(true)
        setId(id)
    }

    const handleClose = () => {
        setOpen(false);
    };

    const data = {
        columns: [
            { title: '#', field: 'count' },
            { title: 'Description', field: 'desc' },
            { title: 'Action', field: 'action' },
        ],
        data: []
    }


    var obj = state.key_data.map((request, index) => {
        return {
            'count': index + 1,
            'desc': request.product_key,
            'action': <div>
                <button className="btn btn-info" onClick={() => handleOpen(request.id)} style={{ marginRight: '10px' }}>Update</button>
                <button className="btn btn-danger">Delete</button>
            </div>

        }
    })

    if (state.redirect) {
        return <Redirect to="/adminDescription" />
    }

    return (
        <div>
            <Nav />
            <div className="container-fluid pt-3" id="main">

                <div className="row row-offcanvas row-offcanvas-left">
                    <SideNav />
                    <div className="col main pt-5 mt-3">
                        <div className="row mb-3">
                            <div className="col-xl-4 col-sm-6 py-2">
                                <form style={{ paddingLeft: '5%', paddingTop: '30px' }}>
                                    <div class="form-group">
                                        <label for="des">Description</label>
                                        <input type="text" class="form-control" id="des" placeholder="Enter Description" onChange={(e) => dispatch(updateDes(e.target.value))} />
                                    </div>
                                    <button type="button" class="btn btn-primary" onClick={() => addDescription(dispatch, state)}>Add</button>
                                </form>
                            </div>
                        </div>
                        <hr />
                        <div className="container col-8">
                            <MaterialTable
                                title="Product Description"
                                columns={data.columns}
                                data={obj}
                                options={{
                                    search: true,
                                    paging: true,
                                }}
                            />
                            {open ?
                                <UpdateModal action="Description" rowId={id} data={state.key_data} open={open} handleClose={handleClose} />
                                : null}
                        </div>
                        <hr />
                    </div>
                </div>
            </div>
            <Footer />

        </div >
    )
}