import React, { useReducer, useEffect, useState } from 'react';
import Nav from '../../Navbar'
import SideNav from '../../SideNav';
import Footer from '../../Footer'
import { getKey, handleSubmit, getProductTypes, getSubProduct } from './network'
import { reducer, intialState } from './reducer'
import Select from 'react-select'
import { updateProduct, updateType } from './actions'

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import MaterialTable from 'material-table'
import { Redirect } from 'react-router-dom';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

export default function AdminSubProducts() {

    const [state, dispatch] = useReducer(reducer, intialState)
    useEffect(() => {
        getKey(dispatch)
        getProductTypes(dispatch)
        getSubProduct(dispatch)
    }, [])


    const [tableData] = useState({
        columns: [
            { title: '#', field: 'count' },
            { title: 'Product Type', field: 'productType' },
            { title: 'Sub Product', field: 'subProduct' },
            { title: 'Action', field: 'action' },
        ],
        data: []
    });

    var obj = state.subProductType.map((request, index) => {
        return {
            'count': index + 1,
            'productType': request.typeName,
            'subProduct': request.name,
            'action': <div>
                <button className="btn btn-info" style={{ marginRight: '10px' }}>Update</button>
                <button className="btn btn-danger">Delete</button>
            </div>

        }
    })


    const [rows, setRows] = useState(0)
    const [key, setKey] = useState("")
    const [value, setValue] = useState("")
    const [data, setData] = useState([])
    const [file, setFile] = useState("")

    var options = state.key_data.map((field) => {
        return {
            'value': field.id,
            'label': field.product_key,
        }
    })

    var type = state.typeList.map((field) => {
        return {
            'value': field.id,
            'label': field.name,
        }
    })

    const handleAddRow = () => {
        handleAdd()
        setRows(rows + 1)
    };
    const handleRemoveRow = () => {
        if (rows !== 0)
            setRows(rows - 1)
    };



    const handleAdd = () => {
        let arr = [...data]
        arr.push({ 'key': key, 'value': value, 'row': rows })
        setData(arr)
    }

    const handleData = () => {
        handleAdd()
    }

    const handleImageChange = (files) => {
        const reader = new FileReader()
        reader.readAsDataURL(files) // assuming "image" is your file
        setFile(reader)

    }

    var elements = [];

    for (var i = 0; i < rows; i++) {
        elements.push(<tr>
            <td style={{ width: '50%' }}>
                <div className="form-group">
                    <Select
                        value={state.selectedOption}
                        options={options} placeholder="Select One"
                        onChange={(selectedOption) => setKey(selectedOption.value)}
                    />
                </div>
            </td>
            <td style={{ width: '50%' }}>
                <div className="form-group">
                    <input type="text" class="form-control" id="val" placeholder="Value" onChange={(e) => setValue(e.target.value)} />
                </div>
            </td>
            <td>
                <button type="button" className="btn btn-primary" onClick={handleAddRow}>Add</button>
            </td>
            <td style={{ marginRight: "2%" }}>
                <button type="button" className="btn btn-secondary" onClick={handleRemoveRow}>Remove</button>
            </td>
        </tr>);
    }

    if (state.redirect) {
        return <Redirect to={state.path} />
    }

    return (
        <div>
            <Nav />
            <div className="container-fluid pt-3" id="main">

                <div className="row row-offcanvas row-offcanvas-left">
                    <SideNav />
                    <div className="col main pt-5 mt-12">
                        <div className="row mb-12">
                            <form enctype="multipart/form-data" style={{ paddingLeft: '5%', paddingTop: '30px' }}>

                                <div class="form-group">
                                    <label for="pname">Product Type</label>
                                    <Select
                                        value={state.selectedOption}
                                        options={type} placeholder="Select One"
                                        onChange={(selectedOption) => dispatch(updateType(selectedOption))}
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="pname">Product Name</label>
                                    <input type="text" class="form-control" id="pname" placeholder="Enter Product Name" onChange={(e) => dispatch(updateProduct(e.target.value))} />
                                </div>
                                <div>
                                    <table border={1}>
                                        <tr>
                                            <td style={{ width: '50%' }}>
                                                <div className="form-group">
                                                    <label for="des">Description</label>
                                                    <Select
                                                        value={state.selectedOption}
                                                        options={options} placeholder="Select One"
                                                        onChange={(selectedOption) => setKey(selectedOption.value)}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ width: '50%' }}>
                                                <div className="form-group">
                                                    <label for="val">Value</label>
                                                    <input type="text" class="form-control" id="val" placeholder="Value" onChange={(e) => setValue(e.target.value)} />
                                                </div>
                                            </td>
                                            <td>
                                                <button type="button" className="btn btn-primary" onClick={handleAddRow}>Add</button>
                                            </td>
                                            {
                                                rows ?

                                                    <td style={{ marginRight: "2%" }}>
                                                        <button type="button" className="btn btn-secondary" onClick={handleRemoveRow}>Remove</button>
                                                    </td>
                                                    :
                                                    null
                                            }

                                        </tr>
                                        {elements}
                                        <tr>
                                            <td><button type="button" class="btn btn-primary" onClick={() => handleData()}>Save</button></td>
                                        </tr>
                                    </table>
                                    <br />
                                    <div className="form-group">
                                        <FilePond
                                            allowMultiple={false}
                                            maxFiles={1}
                                            server={{
                                                // fake server to simulate loading a 'local' server file and processing a file
                                                process: (fieldName, file, metadata, load) => {
                                                    // simulates uploading a file
                                                    setTimeout(() => {
                                                        load(Date.now())
                                                        handleImageChange(file)
                                                    }, 1000);
                                                },
                                                load: (source, load) => {
                                                    // simulates loading a file from the server
                                                    fetch(source).then(res => res.blob()).then(load);
                                                }
                                            }}
                                            required />
                                    </div>
                                </div>
                                <button type="button" class="btn btn-primary" onClick={() => handleSubmit(dispatch, state, data, file)}>Submit</button>
                            </form>
                        </div>
                        <hr />
                        <div className="container col-8">
                            <MaterialTable
                                title="Product Description"
                                columns={tableData.columns}
                                data={obj}
                                options={{
                                    search: true,
                                    paging: true,
                                }}
                            />

                        </div>
                        <hr />
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}