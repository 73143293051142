import * as actions from './action'

export const intialState = {
    isloading: false,
    data: {}
}

export function reducer(state = intialState, action) {
    switch (action.type) {
        case actions.IS_LOADING:
            return { ...state, isLoading: true }
        case actions.IS_LOADED:
            return { ...state, isLoading: false }
        case actions.SET_PRODUCT_DATA:
            return {
                ...state, data: action.payLoad
            }
            case actions.SET_PRODUCT_DESC:
                return {
                    ...state, data: { ...state.data, product_key: action.payLoad }
                }
        default: return state
    }
}

