import * as actions from './action'

export const initialState = {
    isloading: false,
    productType: [],
    subProductType: [],
    redirect: false
}

export function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.IS_LOADING:
            return { ...state, isLoading: true }
        case actions.IS_LOADED:
            return { ...state, isLoading: false }
        case actions.GET_PRODUCT_TYPE:
            return { ...state, productType: action.payLoad }
        case actions.GET_SUB_PRODUCT_TYPE:
            return { ...state, subProductType: action.payLoad }
        case actions.IS_REDIRECT:
            return { ...state, redirect: true }
        default: return state
    }
}

