import network from '../../../../Common/Network'
import * as action from './action';
import { Alert } from '../../../../Common/Alerts'


export async function addDescription(dispatch, state) {
    if (state.des.value) {
        dispatch(action.isLoading())
        try {
            var response = await network.post('productApi/addDescription', {
                'des': state.des.value,
            }, {}, true
            )
            dispatch(action.setRedirect('adminDescription'))
            Alert('success', response.message)
        } catch (error) {
            Alert('warning', error.message)
        }
        dispatch(action.isLoaded())
    } else {
        Alert('warning', "Please Fill All Fields")
    }
}

export async function getKey(dispatch) {
    dispatch(action.isLoading())
    try {
        var response = await network.get('productApi/getKey', true)
        dispatch(action.getProductKey(response))
    } catch (error) {
        Alert('warning', error.message)
    }
    dispatch(action.isLoaded())
}