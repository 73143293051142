import * as actions from './actions'

export const intialState = {
    isloading: false,
    key_data: [],
    typeList: [],
    productName: "",
    redirect: false,
    path: "",
    productType: "",
    subProductType: []
}


export function reducer(state = intialState, action) {
    switch (action.type) {
        case actions.UPDATE_PRODUCT_NAME:
            return { ...state, productName: action.payLoad }
        case actions.GET_PRODCUT_KEY:
            return { ...state, key_data: action.payLoad }
        case actions.IS_LOADING:
            return { ...state, isLoading: true }
        case actions.IS_LOADED:
            return { ...state, isLoading: false }
        case actions.IS_REDIRECT:
            return {
                ...state,
                redirect: true,
                path: action.payLoad
            };
        case actions.UPDATE_TYPE:
            return { ...state, productType: action.payLoad }
        case actions.GET_TYPE_LIST:
            return { ...state, typeList: action.payLoad }
        case actions.GET_SUB_PRODCUT_TYPE:
            return { ...state, subProductType: action.payLoad }
        default: return state
    }
}

