import React, { useReducer, useState, useEffect } from 'react';
import Nav from '../../Navbar'
import SideNav from '../../SideNav';
import Footer from '../../Footer';
import { updateProduct } from './action'
import { addProduct, getProduct } from './network'
import { reducer, intialState } from './reducer'
import MaterialTable from 'material-table'
import { Redirect } from 'react-router-dom'


export default function AddProduct() {

    const [state, dispatch] = useReducer(reducer, intialState)

    useEffect(() => {
        getProduct(dispatch)
    }, [])

    const [data] = useState({
        columns: [
            { title: '#', field: 'count' },
            { title: 'Product Type', field: 'productName' },
            { title: 'Action', field: 'action' },
        ],
        data: []
    });


    var obj = state.productType.map((request, index) => {
        return {
            'count': index + 1,
            'productName': request.name,
            'action': <div>
                <button className="btn btn-info" style={{ marginRight: '10px' }}>Update</button>
                <button className="btn btn-danger">Delete</button>
            </div>

        }
    })

    if (state.redirect) {
        return <Redirect to={state.path} />
    }

    return (
        <div>
            <Nav />
            <div className="container-fluid pt-3" id="main">

                <div className="row row-offcanvas row-offcanvas-left">
                    <SideNav />
                    <div className="col main pt-5 mt-3">
                        <div className="row mb-3">
                            <div className="col-xl-4 col-sm-6 py-2">
                                <form style={{ paddingLeft: '5%', paddingTop: '30px' }}>
                                    <div class="form-group">
                                        <label for="des">Product Type</label>
                                        <input type="text" class="form-control" id="des" placeholder="Enter Product Type Name" onChange={(e) => dispatch(updateProduct(e.target.value))} />
                                    </div>
                                    <button type="button" class="btn btn-primary" onClick={() => addProduct(dispatch, state)}>Add</button>
                                </form>
                            </div>
                        </div>
                        <hr />
                        <div className="container col-8">
                            <MaterialTable
                                title="Product List"
                                columns={data.columns}
                                data={obj}
                                options={{
                                    search: true,
                                    paging: true,
                                }}
                            />

                        </div>
                        <hr />
                    </div>
                </div>
            </div>
            <Footer />

        </div >
    )
}