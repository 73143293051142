import * as actions from './action'
import { validateProduct } from '../../../../Common/Validator'

export const intialState = {
    isloading: false,
    product: {
        value: "",
        errorStatus: false,
        errorMessage: "Enter Valid Description"
    },

    redirect: false,
    path: "",
    productType: []
}



export function reducer(state = intialState, action) {
    switch (action.type) {
        case actions.UPDATE_PRODUCT:
            return {
                ...state,
                product: {
                    ...state.des,
                    value: action.payLoad,
                    errorStatus: validateProduct(action.payLoad) ? false : true
                },
            };
        case actions.IS_LOADING:
            return { ...state, isLoading: true }
        case actions.IS_LOADED:
            return { ...state, isLoading: false }
        case actions.IS_REDIRECT:
            return {
                ...state,
                redirect: true,
                path: action.payload
            };
        case actions.SET_PRODUCT_TYPE:
            return {
                ...state, productType: action.payload
            }
        default: return state
    }
}

