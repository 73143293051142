import network from '../../../../Common/Network'
import * as action from './action';
import { Alert } from '../../../../Common/Alerts'


export async function addProduct(dispatch, state) {
    if (state.product.value) {
        dispatch(action.isLoading())
        try {
            var response = await network.post('productApi/addProduct', {
                'product': state.product.value,
            }, {}, true
            )
            Alert('success', response.message)
            dispatch(action.setRedirect('/adminProducts'))
        } catch (error) {
            Alert('warning', error.message)
        }
        dispatch(action.isLoaded())
    } else {
        Alert('warning', "Please Fill All Fields")
    }
}

export async function getProduct(dispatch) {
    dispatch(action.isLoading())
    try {
        var response = await network.get('productApi/getProductType', true)
        dispatch(action.setProductType(response))
    } catch (error) {
        Alert('warning', error.message)
    }
    dispatch(action.isLoaded())
} 
