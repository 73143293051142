import * as actions from './actions'
import * as validate from '../../Common/Validator';


export const intialState = {
    isloading: false,
    email: {
        value: "",
        errorStatus: false,
        errorMessage: "Enter Valid Email"
    },
    mobile: {
        value: "",
        errorStatus: false,
        errorMessage: "Enter Valid Mobile"
    },
    password: {
        value: "",
        errorStatus: false,
        errorMessage: "Enter Valid Password"
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case actions.UPDATE_EMAIL:
            return {
                ...state,
                email: {
                    ...state.email,
                    value: action.payLoad,
                    errorStatus: validate.validateEmail(action.payLoad) ? false : true
                },
            };
        case actions.UPDATE_MOBILE:
            return {
                ...state,
                mobile: {
                    ...state.mobile,
                    value: action.payLoad,
                    errorStatus: validate.validateMobile(action.payLoad) ? false : true
                },
            };
        case actions.UPDATE_PASSWORD:
            return {
                ...state,
                password: {
                    ...state.password,
                    value: action.payLoad,
                    errorStatus: validate.validatePassword(action.payLoad) ? false : true
                },
            };
        case actions.IS_LOADING:
            return { ...state, isLoading: true };
        case actions.IS_LOADED:
            return { ...state, isLoading: false };
        default: return state
    }
}