export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const IS_LOADING = 'IS_LOADING'
export const IS_LOADED = 'IS_LOADED'
export const IS_REDIRECT = 'IS_REDIRECT'
export const SET_PRODUCT_TYPE = 'SET_PRODUCT_TYPE'


export function isLoading() {
    return { type: IS_LOADING };
}
export function isLoaded() {
    return { type: IS_LOADED }
}
export function updateProduct(des) {
    return {
        type: UPDATE_PRODUCT,
        payLoad: des
    }
}
export const setRedirect = (path) => {
    return {
        type: IS_REDIRECT,
        payload: path
    }
}
export const setProductType = (data) => {
    return {
        type: SET_PRODUCT_TYPE,
        payload: data
    }
}

