import React, { useEffect, useReducer } from 'react';
import Navbar from '../../Common/Navbar';
import Footer from '../../Common/Footer';
import { reducer, initialState } from './reducer';
import { getProductDetails, getProductDesc } from './network';
import CircularProgress from '@material-ui/core/CircularProgress';

function ProductList(props) {


    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        getProductDetails(dispatch, props.location.state.pType)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let data = state.productData && state.productData.map(x => { return x.id })
        getProductDesc(dispatch, data)
    }, [state.productData])

    var name = state.productData.map(x => { return x.typeName })

    return (
        <div>
            <Navbar />
            <br /><br />
            {
                state.isLoading ? <CircularProgress style={{  position: 'absolute', left: '50%', top: '50%' }} disableShrink /> :
                    <div>
                        <section className="services" style={{ backgroundColor: 'white' }}>
                            <div className="container">
                                <div className="section-title" data-aos="fade-in" data-aos-delay="100">
                                    <h2>{name[0]}</h2>
                                </div>
                                <div className="row">
                                    {state.productData.map((x, key) => {
                                        return (
                                            <div key={key} className="col-md-12 products-list">
                                                <div className="icon-box" data-aos="fade-up">
                                                    <div className="icon"><img src={x.image_path} style={{ width: '40%' }} alt="" /></div>
                                                    <h4 className="title">{x.productName}</h4>
                                                    {
                                                        state.productDesc && state.productDesc.filter(y => y.product_id === x.id).map((z, key) => {
                                                            return (
                                                                <p key={key} className="description">{z.product_key}: <strong>{z.product_value}</strong></p>
                                                            )
                                                        })
                                                    }
                                                    <hr></hr>
                                                    <p><strong>Certificate</strong></p>
                                                    <hr></hr>
                                                    <p>Below Certificates Can be Arranged as per Client's Requirement</p>
                                                    <li>Certificate of Origin</li>
                                                    <li>Insurance</li>
                                                    <li>Inspection</li>
                                                    <li>Fumigation</li>
                                                    <li>Phytosanitary</li>
                                                    <li>Conformity</li>
                                                    <li>Quality & Quantity Certificate</li>
                                                    <li>Third Party Inspection Certificate as per Client's Requirement</li>
                                                    <hr></hr>
                                                    <p><strong>Packing</strong></p>
                                                    <hr></hr>
                                                    {
                                                        name[0] === "Molasses" ?
                                                            <li>Flexi Tank, IBC Tank 1000 ltr, Plastic Tank 200 ltr</li>
                                                            :
                                                            <li>PP Bag, Jute Bag, Cotton Bag, or Non-woven Fabric Bag Packing of 20Kg, 25Kg, 50Kg or 100Kg</li>

                                                    }
                                                    <hr></hr>

                                                    <p><strong>Terms of Delivery</strong></p>
                                                    <hr></hr>
                                                    <li>FOB (Free on Board)</li>
                                                    <li>CFR (Cost & Freight)</li>
                                                    <li>CIF (Cost, Insurance & Freight)</li>
                                                    <hr></hr>
                                                    <p><strong>Payment Terms</strong></p>
                                                    <hr></hr>
                                                    <li>Payment through Bank Wire Transfer</li>
                                                    <li>Irrevocable Confirmed Letter of Credit (L/C) at Sight From Reputed Bank</li>



                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </section>

                    </div>
            }
            {
                !state.isLoading ?
                    <Footer />
                    : null
            }

        </div>
    )
}
export default ProductList;