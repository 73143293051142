import React, { useReducer } from 'react';
import { Link } from 'react-router-dom';
import { reducer, intialState } from './reducer';
import { updateEmail, updateMobile, updatePassword } from './actions';
import { register } from './network';

function Registration() {
    const [state, dispatch] = useReducer(reducer, intialState)
    return (
        <div>
            <div className="wrapper fadeInDown" style={{ paddingTop: '10%' }}>
                <div id="formContent">
                    <div className="fadeIn first">
                        <img src="/assets/img/logo.png" id="icon" alt="User Icon" />
                    </div>
                    <form>
                        <input type="text" className="fadeIn second" placeholder="Email" onChange={(e) => dispatch(updateEmail(e.target.value))} />
                        <input type="text" className="fadeIn second" name="login" placeholder="Mobile" onChange={(e) => dispatch(updateMobile(e.target.value))} />
                        <input type="password" id="password" className="fadeIn third" name="login" placeholder="Password" onChange={(e) => dispatch(updatePassword(e.target.value))} />
                        <input type="button" className="fadeIn fourth" value="Sign up"
                            onClick={() => {
                                register(dispatch, state)
                            }} />
                    </form>
                    <div id="formFooter">
                        <Link className="underlineHover" to="/admin">Already User ?</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Registration