import React from 'react'

export default function Footer() {
    return (
        <div>
            <footer className="container-fluid">
                <p className="text-right small">© 2020 Inoveda</p>
            </footer>
        </div>
    )
}