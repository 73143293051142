import React, { useReducer, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { intialState, reducer } from "./reducer";
import { setProduct, updateDesc } from "./action";
import { updateProductDesc } from './network'


const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        padding: theme.spacing(2, 4, 3),
        width: "71%",
        marginTop: "1%",
    },
}));


export default function UpdateModal(props) {

    const [state, dispatch] = useReducer(reducer, intialState)

    let data = props.data.filter(x => x.id === props.rowId)


    useEffect(() => {
        dispatch(setProduct(data[0]))
        // eslint-disable-next-line
    }, [])


    const classNames = useStyles();
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <p className="modal-title caps">
                                        <strong>Update {props.action} Information </strong>
                                    </p>
                                </div>
                                <div className="container mt-2">
                                    <form>
                                        <div className="form-group">
                                            <label for="descrption">Description</label>
                                            <input type="text" className="form-contorl" value={state.data.product_key}
                                                onChange={
                                                    (event) => {
                                                        dispatch(updateDesc(event.target.value))
                                                    }}
                                            />
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        onClick={() => { updateProductDesc(dispatch, state) }}
                                        className="btn btn-success"
                                        data-dismiss="modal"
                                    >
                                        Update
                                    </button>
                                    <button
                                        type="button"
                                        onClick={props.handleClose}
                                        className="btn btn-danger"
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
