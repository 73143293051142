import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4 col-md-6">
                                <div className="footer-info" data-aos="fade-up" data-aos-delay="50">
                                    <h3>innoveda</h3>
                                    <p className="pb-3"><em> Innoveda International General Trading LLC is associated with some of the biggest brands in the region,a trusted link connecting brands to wholesale and retail market in UAE. We are the trade strategy experts of the wholesale market in the region with a diverse portfolio of brands for a variety of consumer needs.</em></p>
                                    <p>
                                        Al Qusais Industrial AreaAl Qusais Industrial Area 3<br />
                                    Dubai - United Arab Emirates<br /><br />
                                        <strong>Phone:</strong> +971 52 436 4847<br />
                                        <strong>Email:</strong> contact@innovedaintl.com<br />
                                    </p>
                                    <div className="social-links mt-3">
                                        <a href="#hero" className="twitter"><i className="bx bxl-twitter"></i></a>
                                        <a href="#hero" className="facebook"><i className="bx bxl-facebook"></i></a>
                                        <a href="#hero" className="instagram"><i className="bx bxl-instagram"></i></a>
                                        <a href="#hero" className="google-plus"><i className="bx bxl-skype"></i></a>
                                        <a href="#hero" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-6 footer-links" data-aos="fade-up" data-aos-delay="150">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#hero">Home</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#about">About us</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#products">Products</a></li>
                                </ul>
                            </div>

                            {/* <div className="col-lg-2 col-md-6 footer-links" data-aos="fade-up" data-aos-delay="250">
                                <h4>Our Services</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">FoodStuff Products
</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Neutaceuticals Products
</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Petrochemical Products
</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Office Products</a></li>
                                </ul>
                            </div> */}

                            {/* <div className="col-lg-4 col-md-6 footer-newsletter" data-aos="fade-up" data-aos-delay="350">
                                <h4>Our Newsletter</h4>
                                <form action="" method="post">
                                    <input type="email" name="email" /><input type="submit" value="Subscribe" />
                                </form>
                            </div> */}

                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>innoveda</span></strong>. All Rights Reserved
      </div>
                    <div className="credits">
                        Designed by <a href="http://teamtwo.in/" target="_blank" rel="noopener noreferrer">teamtwo</a>  <Link to="/admin">Admin</Link>
                    </div>
                </div>
            </footer>

        </div>
    )
}
export default Footer;