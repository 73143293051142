import * as actions from './action'

export const initialState = {
    isloading: false,
    productData: [],
    productDesc: []
}

export function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.IS_LOADING:
            return { ...state, isLoading: true }
        case actions.IS_LOADED:
            return { ...state, isLoading: false }
        case actions.GET_PRODUCT_DATA:
            return { ...state, productData: action.payLoad }
        case actions.GET_PRODUCT_DESC:
            return { ...state, productDesc: action.payLoad }
        default: return state
    }
}