import * as actions from './action'
import { validateDes } from '../../../../Common/Validator'

export const initialState = {
    isloading: false,
    des: {
        value: "",
        errorStatus: false,
        errorMessage: "Enter Valid Description"
    },
    redirect: false,
    path: "",
    key_data: []
}

export function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.UPDATE_DES:
            return {
                ...state,
                des: {
                    ...state.des,
                    value: action.payLoad,
                    errorStatus: validateDes(action.payLoad) ? false : true
                },
            };
        case actions.IS_LOADING:
            return { ...state, isLoading: true }
        case actions.IS_LOADED:
            return { ...state, isLoading: false }
        case actions.IS_REDIRECT:
            return {
                ...state,
                redirect: true,
                path: action.payLoad
            }
        case actions.GET_PRODUCT_KEY:
            return { ...state, key_data: action.payLoad }
        default: return state
    }
}

