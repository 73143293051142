import network from '../Network'
import * as action from './action';
import { Alert } from '../Alerts'


export async function updateProductDesc(dispatch, state) {
    dispatch(action.isLoading())
    try {
        var response = await network.post('productApi/updateProductDesc', {
            'product': state.data
        }, {}, true
        )
        Alert('success', response.message)
    } catch (error) {
        Alert('warning', error.message)
    }
    dispatch(action.isLoaded())
}


