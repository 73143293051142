export const UPDATE_DES = 'UPDATE_DES'
export const IS_LOADING = 'IS_LOADING'
export const IS_LOADED = 'IS_LOADED'
export const IS_REDIRECT = 'IS_REDIRECT'
export const GET_PRODCUT_KEY = 'GET_PRODCUT_KEY'
export const UPDATE_PRODUCT_NAME = 'UPDATE_PRODUCT_NAME'
export const UPDATE_TYPE = 'UPDATE_TYPE'
export const GET_TYPE_LIST = 'GET_TYPE_LIST'
export const GET_SUB_PRODCUT_TYPE = 'GET_SUB_PRODCUT_TYPE'

export function isLoading() {
    return { type: IS_LOADING };
}
export function isLoaded() {
    return { type: IS_LOADED }
}
export function getProductKey(data) {
    return {
        type: GET_PRODCUT_KEY,
        payLoad: data
    }
}
export const setRedirect = (path) => {
    return {
        type: IS_REDIRECT,
        payLoad: path
    }
}
export const updateProduct = (data) => {
    return {
        type: UPDATE_PRODUCT_NAME,
        payLoad: data
    }
}

export const getTypeList = (data) => {
    return {
        type: GET_TYPE_LIST,
        payLoad: data
    }
}
export const updateType = (data) => {
    return {
        type: UPDATE_TYPE,
        payLoad: data
    }
}
export function getSubProductType(data) {
    return {
        type: GET_SUB_PRODCUT_TYPE,
        payLoad: data
    }
}


