export const UPDATE_DES = 'UPDATE_DES'
export const IS_LOADING = 'IS_LOADING'
export const IS_LOADED = 'IS_LOADED'
export const IS_REDIRECT = 'IS_REDIRECT'
export const GET_PRODUCT_KEY = 'GET_PRODUCT_KEY'

export function isLoading() {
    return { type: IS_LOADING };
}
export function isLoaded() {
    return { type: IS_LOADED }
}
export function updateDes(des) {
    return {
        type: UPDATE_DES,
        payLoad: des
    }
}
export const setRedirect = (path) => {
    return {
        type: IS_REDIRECT,
        payLoad: path
    }
}
export function getProductKey(data) {
    return {
        type: GET_PRODUCT_KEY,
        payLoad: data
    }
}
