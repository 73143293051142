export const IS_LOADING = 'IS_LOADING'
export const IS_LOADED = 'IS_LOADED'
export const GET_PRODUCT_TYPE = 'GET_PRODUCT_TYPE'
export const GET_SUB_PRODUCT_TYPE = 'GET_SUB_PRODUCT_TYPE'
export const IS_REDIRECT = 'IS_REDIRECT'

export function isLoading() {
    return { type: IS_LOADING };
}
export function isLoaded() {
    return { type: IS_LOADED }
}
export function getProductType(data) {
    return {
        type: GET_PRODUCT_TYPE,
        payLoad: data
    }
}
export function getSubProductType(data) {
    return {
        type: GET_SUB_PRODUCT_TYPE,
        payLoad: data
    }
}
export function redirect(){
    return {
        type : IS_REDIRECT
    }
}