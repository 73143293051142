import React from 'react';
import Nav from './Navbar'
import SideNav from './SideNav';
import Footer from './Footer';

export default function Home() {
    return (
        <div>
            <Nav />
            <div className="container-fluid pt-3" id="main">

                <div className="row row-offcanvas row-offcanvas-left">
                    <SideNav />
                    <div className="col main pt-5 mt-3">
                        <div className="row mb-3">
                            <div className="col-xl-3 col-sm-6 py-2">
                                <div className="card bg-success text-white h-100">
                                    <div className="card-body bg-success">
                                        <div className="rotate">
                                            <i className="fa fa-user fa-4x"></i>
                                        </div>
                                        <h6 className="text-uppercase">Products</h6>
                                        <h1 className="display-4">134</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6 py-2">
                                <div className="card text-white bg-danger h-100">
                                    <div className="card-body bg-danger">
                                        <div className="rotate">
                                            <i className="fa fa-list fa-4x"></i>
                                        </div>
                                        <h6 className="text-uppercase">Sub Products</h6>
                                        <h1 className="display-4">87</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6 py-2">
                                <div className="card text-white bg-info h-100">
                                    <div className="card-body bg-info">
                                        <div className="rotate">
                                            <i className="fa fa-twitter fa-4x"></i>
                                        </div>
                                        <h6 className="text-uppercase">Description</h6>
                                        <h1 className="display-4">125</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6 py-2">
                                <div className="card text-white bg-warning h-100">
                                    <div className="card-body">
                                        <div className="rotate">
                                            <i className="fa fa-share fa-4x"></i>
                                        </div>
                                        <h6 className="text-uppercase">User Requests</h6>
                                        <h1 className="display-4">36</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />

                    </div>
                </div>
            </div>
            <Footer />

        </div>
    )
}