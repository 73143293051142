import React from 'react';
import { Link } from 'react-router-dom';
function Navbar() {
    return (
        <div>
            <header id="header" className="fixed-top header-transparent ">
                <div className="container d-flex">
                    <div className="logo mr-auto">
                       <Link to='/'><img src="./assets/img/home_logo.png"  width="280px" alt="logo" /></Link>
                    </div>
                    <nav className="nav-menu d-none d-lg-block">
                        <ul>
                            <li className="active"><Link to="/">Home</Link></li>
                            <li><a href="#about">About Us</a></li>
                            <li><a href="#products">Products</a></li>
                            {/* <li><a href="#team">Team</a></li> */}
                            {/* <li className="drop-down"><a href="">Drop Down</a>
                                <ul>
                                    <li><a href="#">Drop Down 1</a></li>
                                    <li className="drop-down"><a href="#">Drop Down 2</a>
                                        <ul>
                                            <li><a href="#">Deep Drop Down 1</a></li>
                                            <li><a href="#">Deep Drop Down 2</a></li>
                                            <li><a href="#">Deep Drop Down 3</a></li>
                                            <li><a href="#">Deep Drop Down 4</a></li>
                                            <li><a href="#">Deep Drop Down 5</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="#">Drop Down 3</a></li>
                                    <li><a href="#">Drop Down 4</a></li>
                                    <li><a href="#">Drop Down 5</a></li>
                                </ul>
                            </li> */}
                            <li><a href="#contact">Contact Us</a></li>

                        </ul>
                    </nav>
                </div>
            </header>
        </div >
    )
}
export default Navbar;