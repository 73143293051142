import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom"
import LandingPage from './Components/LandingPage'
import Login from './Components/Admin/Login'
import Register from './Components/Admin/Registration'
import ForgetPassword from './Components/Admin/ForgetPassword'
import Home from './Components/Admin/Home'
import AdminProducts from './Components/Admin/Home/Products/Product'
import AdminSubProducts from './Components/Admin/Home/Products/SubProduct'
import AdminDescription from './Components/Admin/Home/Products/Description'
import ProductList from './Components/SubComponents/ProductList';
import AdminCustomers from './Components/Admin/Home/Customers'
import AdminRoles from './Components/Admin/Home/Roles'
import ScrollToTop from './Components/Common/Scroll';
function App() {
    return (
        <BrowserRouter>
            <ScrollToTop>
                <Switch>
                    <Route path="/" component={LandingPage} exact />
                    <Route path="/productList" component={ProductList} exact />
                    <Route path="/admin" component={Login} exact />
                    <Route path="/register" component={Register} exact />
                    <Route path="/forget" component={ForgetPassword} exact />
                    <Route path='/home' component={Home} exact />
                    <Route path="/adminProducts" component={AdminProducts} exact />
                    <Route path="/adminSubProducts" component={AdminSubProducts} exact />
                    <Route path="/adminDescription" component={AdminDescription} exact />
                    <Route path="/adminCustomers" component={AdminCustomers} exact />
                    <Route path="/adminRoles" component={AdminRoles} exact />
                </Switch>
            </ScrollToTop>
        </BrowserRouter>

    )
}
export default App;