import network from '../../Common/Network'
import * as action from './actions';
import { Alert } from '../../Common/Alerts'

export async function register(dispatch, state) {
    if (state.email.value && state.mobile.value && state.password.value) {
        dispatch(action.isLoading())
        try {
            var response = await network.post('authApi/userRegister', {
                'email': state.email.value,
                'mobile': state.mobile.value,
                'password': state.password.value
            }, {}, false
            )
            Alert('success', response.message)
        } catch (error) {
            Alert('warning', error.message)
        }
        dispatch(action.isLoaded())
    } else {
        Alert('warning', "Please Fill All Fields")
    }
}