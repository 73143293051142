export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const UPDATE_MOBILE = 'UPDATE_MOBILE'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const IS_LOADING = 'ISLOADING'
export const IS_LOADED = 'ISLOADED'

export function isLoading() {
    return { type: IS_LOADING };
}
export function isLoaded() {
    return { type: IS_LOADED }
}
export function updateEmail(email) {
    return {
        type: UPDATE_EMAIL,
        payLoad: email
    }
}
export function updateMobile(mobile) {
    return {
        type: UPDATE_MOBILE,
        payLoad: mobile
    }
}
export function updatePassword(password) {
    return {
        type: UPDATE_PASSWORD,
        payLoad: password
    }
}